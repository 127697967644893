import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { FaInstagram, FaLinkedin, FaFacebook } from 'react-icons/fa';
import Logo from './Logo';

const FooterContainer = styled(motion.footer)`
  background: ${props => props.$isProjectPage 
    ? '#050411' 
    : 'linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgb(18, 18, 18) 100%)'};
  padding: 80px 0 40px;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 999;

  @media (max-width: 768px) {
    padding: 60px 0 30px;
  }
`;

const FooterContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 40px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 40px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 768px) {
    padding: 0 20px;
    grid-template-columns: minmax(0, 1fr);
    gap: 30px;
  }
`;

const FooterSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  min-width: 0; 
  flex: 1; 
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
  font-family: 'HelveticaNowVar', sans-serif;
  white-space: nowrap; 
`;

const FooterLink = styled(motion(Link))`
  color: #888;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1rem;
  position: relative;
  width: fit-content;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #fff;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #fff;
    &:after {
      width: 100%;
    }
  }
`;

const ExternalLink = styled.a`
  color: #888;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1rem;
  position: relative;
  width: fit-content;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #fff;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #fff;
    &:after {
      width: 100%;
    }
  }
`;

const FooterDescription = styled.p`
  color: #888;
  font-size: 1rem;
  margin: 20px 0;
  max-width: 300px;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
`;

const SocialIcon = styled.a`
  color: #fff;
  font-size: 24px;
  transition: all 0.3s ease;
  opacity: 0.7;

  &:hover {
    opacity: 1;
    transform: translateY(-2px);
  }
`;

const Copyright = styled(motion.div)`
  max-width: 1400px;
  margin: 60px auto 0;
  padding: 20px 40px 0;
  text-align: center;
  color: #888;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 768px) {
    padding: 20px 20px 0;
  }
`;

const Footer = () => {
  const location = useLocation();
  const isProjectPage = location.pathname.includes('/project/');

  return (
    <FooterContainer $isProjectPage={isProjectPage}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ 
        duration: 0.8,
        delay: 2,
        ease: "easeOut"
      }}
    >
      <FooterContent>
        <FooterSection>
          <Logo />
          <FooterDescription>
            All4 Agency - All for you. We turn your digital dreams into reality.
          </FooterDescription>
          <SocialIcons>
            <SocialIcon href="https://instagram.com" target="_blank">
              <FaInstagram />
            </SocialIcon>
            <SocialIcon href="https://linkedin.com" target="_blank">
              <FaLinkedin />
            </SocialIcon>
            <SocialIcon href="https://facebook.com" target="_blank">
              <FaFacebook />
            </SocialIcon>
          </SocialIcons>
        </FooterSection>

        <FooterSection>
          <Title>Services</Title>
          <FooterLink to="/services/creative-direction">Creative Direction</FooterLink>
          <FooterLink to="/services/video-production">Video Production</FooterLink>
          <FooterLink to="/services/brand-identity">Brand Identity</FooterLink>
          <FooterLink to="/services/digital-solutions">Digital Solutions</FooterLink>
        </FooterSection>

        <FooterSection>
          <Title>Legal</Title>
          <FooterLink to="/privacy">Privacy Policy</FooterLink>
          <FooterLink to="/terms">Terms of Service</FooterLink>
          <FooterLink to="/cookies">Cookies Policy</FooterLink>
          <FooterLink to="/faq">FAQ</FooterLink>
        </FooterSection>

        <FooterSection>
          <Title>Contact</Title>
          <ExternalLink href="tel:+212707370707">+212 707 370 707</ExternalLink>
          <ExternalLink href="mailto:hello@all4agency.com">hello@all4agency.com</ExternalLink>
          <ExternalLink href="https://wa.me/212707370707" target="_blank">WhatsApp Chat</ExternalLink>
          <ExternalLink href="https://calendly.com/all4agency/30min" target="_blank">Book a Meeting</ExternalLink>
        </FooterSection>
      </FooterContent>
      <Copyright>
        &copy; 2024 All4 Agency - All rights reserved
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;

import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useProject } from '../hooks/useWordPress';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CTA from './CTA';

gsap.registerPlugin(ScrollTrigger);

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const scaleIn = keyframes`
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
`;

const createBackgroundAnimation = (rect, backgroundColor) => keyframes`
  0% {
    clip-path: inset(
      ${rect.y}px 
      ${window.innerWidth - (rect.x + rect.width)}px 
      ${window.innerHeight - (rect.y + rect.height)}px 
      ${rect.x}px
    );
    background-color: ${backgroundColor};
    opacity: 1;
  }
  100% {
    clip-path: inset(0 0 0 0);
    background-color: ${backgroundColor};
    opacity: 1;
  }
`;

const createTitleAnimation = (startX, startY, startFontSize) => keyframes`
  0% {
    transform: translate(${startX}px, ${startY}px);
    font-size: ${startFontSize};
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    font-size: 40px;
    opacity: 1;
  }
`;

const createVideoAnimation = (startX, startY, startScale) => keyframes`
  0% {
    transform: translate(${startX}px, ${startY}px) scale(${startScale});
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
`;

const createCategoryAnimation = (startX, startY) => keyframes`
  0% {
    transform: translate(${startX}px, ${startY}px);
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
`;

const Title = styled.h1`
  font-size: 64px !important;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text-color, #fff);
  font-family: var(--primary-font);
  text-align: left;
  position: relative;
  white-space: nowrap;
  opacity: 0;
  will-change: transform, font-size, opacity;
  transition: color 0.3s ease;
  line-height: 1.2;
  
  @media (max-width: 768px) {
    font-size: 40px !important;
    white-space: normal;
  }
  
  @media (max-width: 480px) {
    font-size: clamp(2.5rem, 5vw, 3rem);
  }
  
  ${({ $startPosition }) => $startPosition && css`
    animation: ${createTitleAnimation(
      $startPosition.x, 
      $startPosition.y, 
      $startPosition.fontSize || '24px'
    )} 1.2s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  `}
`;

const VideoContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  margin: 2rem auto;
  opacity: 0;
  will-change: transform, opacity;
  ${({ $startPosition, $animate }) => $startPosition && $animate && css`
    animation: ${createVideoAnimation(
      $startPosition.x,
      $startPosition.y,
      $startPosition.scale || 0.5
    )} 1.2s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    animation-delay: 0.3s;
  `}

  video {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 30px;
  }
`;

const Description = styled.p`
  font-size: 24px;
  color: var(--text-color, #fff);
  opacity: 0;
  margin-bottom: 2rem;
  max-width: 800px;
  line-height: 1.6;
  font-family: var(--primary-font);
  animation: ${fadeIn} 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: 0.3s;
  ${({ $startPosition }) => $startPosition && css`
    animation: ${createCategoryAnimation(
      $startPosition.x,
      $startPosition.y
    )} 1.2s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    animation-delay: 0.15s; // Slight delay after title animation
  `}
  transition: color 0.3s ease;
`;

const Content = styled.div`
  max-width: 1400px;
  margin: 60px auto 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  color: var(--text-color, #ffffff);
  transition: color 0.3s ease;
  padding-bottom: 80px;
`;

const ProjectDetails = styled.div`
  width: 100%;
  max-width: 1400px;
  margin-top: 4rem;
  opacity: 0;
  animation: ${fadeIn} 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: 0.6s;
`;

const ProjectSection = styled.div`
  margin-bottom: 3rem;
  width: 100%;
  overflow-x: hidden;
`;

const SectionTitle = styled.h3`
  font-family: var(--primary-font);
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
  color: var(--text-color, #fff);
  font-weight: 700;
  letter-spacing: -0.02em;
  
  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`;

const SectionText = styled.div`
  font-family: var(--primary-font);
  font-size: 1.8rem;
  line-height: 1.6;
  color: var(--text-color, #fff);
  max-width: 800px;
  font-weight: 300;
  letter-spacing: -0.01em;
  width: 100%;
  
  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 1.5;
    padding-right: 10px;
    max-width: 100%;
  }
`;

const Categories = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 3rem;
  color: var(--text-color, #ffffff);
  transition: color 0.3s ease;
  font-family: var(--primary-font);
  font-size: 1.8rem;
  line-height: 1.6;
  font-weight: 300;
  letter-spacing: -0.01em;
  
  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 1.5;
  }
`;

const Category = styled.span`
  color: var(--text-color, #ffffff);
  font-family: var(--primary-font);
  font-size: 1.8rem;
  line-height: 1.6;
  font-weight: 300;
  letter-spacing: -0.01em;
  
  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 1.5;
  }
`;

const ImageGalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

const ImageRow = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
`;

const ProjectImage = styled.div`
  flex: ${props => props.$isSingle ? '1' : '0 0 calc(50% - 0.25rem)'};
  aspect-ratio: ${props => props.$isSingle ? '16/9' : '9/10'};
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ProjectGallery = ({ images }) => {
  const rows = [];
  let currentIndex = 0;

  while (currentIndex < images.length) {
    if (currentIndex % 3 === 0) {
      // Single image row
      rows.push(
        <ImageRow key={currentIndex}>
          <ProjectImage $isSingle>
            <img src={images[currentIndex]} alt={`Project ${currentIndex + 1}`} />
          </ProjectImage>
        </ImageRow>
      );
      currentIndex++;
    } else {
      // Double image row
      const remainingImages = images.length - currentIndex;
      if (remainingImages >= 2) {
        rows.push(
          <ImageRow key={currentIndex}>
            <ProjectImage>
              <img src={images[currentIndex]} alt={`Project ${currentIndex + 1}`} />
            </ProjectImage>
            <ProjectImage>
              <img src={images[currentIndex + 1]} alt={`Project ${currentIndex + 2}`} />
            </ProjectImage>
          </ImageRow>
        );
        currentIndex += 2;
      } else {
        // Last single image
        rows.push(
          <ImageRow key={currentIndex}>
            <ProjectImage $isSingle>
              <img src={images[currentIndex]} alt={`Project ${currentIndex + 1}`} />
            </ProjectImage>
          </ImageRow>
        );
        currentIndex++;
      }
    }
  }

  return <ImageGalleryContainer>{rows}</ImageGalleryContainer>;
};

const ProjectOutcome = styled.div`
  margin-top: 2rem;
  margin-bottom: 3rem;
`;

const ProjectOutcomeTitle = styled.h3`
  font-family: var(--primary-font);
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
  color: var(--text-color, #fff);
  font-weight: 700;
  letter-spacing: -0.02em;
  
  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`;

const ProjectOutcomeContent = styled.div`
  font-family: var(--primary-font);
  font-size: 1.8rem;
  line-height: 1.6;
  color: var(--text-color, #fff);
  max-width: 800px;
  font-weight: 300;
  letter-spacing: -0.01em;
  width: 100%;
  
  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 1.5;
    padding-right: 10px;
    max-width: 100%;
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-family: var(--primary-font);
  color: var(--text-color, #fff);
`;

const LoadingText = styled.p`
  font-size: 24px;
  font-family: var(--primary-font);
  color: var(--text-color, #fff);
`;

const ErrorContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-family: var(--primary-font);
  color: var(--text-color, #fff);
`;

const ErrorText = styled.p`
  font-size: 24px;
  font-family: var(--primary-font);
  color: var(--text-color, #fff);
`;

const AnimatedBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${props => props.$backgroundColor};
  transition: background-color 0.3s ease;
  will-change: clip-path, opacity;
  
  ${props => props.$started && props.$cardRect && css`
    animation: ${props => createBackgroundAnimation(props.$cardRect, props.$backgroundColor)} 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  `}
`;

const ScrollBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: var(--background-color, transparent);
  opacity: var(--opacity, 0);
  transition: opacity 0.3s ease;
`;

const AnimatedContainer = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
  color: var(--text-color, #ffffff);
  transition: color 0.3s ease;
`;

const PageContainer = styled.div`
  background: transparent;
  color: white;
  padding: 120px 5vw 0 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--primary-font);
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: var(--background-color, #000);
  color: var(--text-color, #fff);
  transition: background-color 0.3s ease, color 0.3s ease;

  & + footer {
    background: #050411 !important;
    background: linear-gradient(180deg, #050411 0%, #050411 100%) !important;
  }
`;

const CTAWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
  
  > div {
    background: #050411;
    max-width: 100%;
  }
`;

const MuteButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
  
  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  svg {
    width: 20px;
    height: 20px;
    fill: white;
  }
`;

const ActualProjectPage = () => {
  const { slug } = useParams();
  const { project, loading, error } = useProject(slug);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [rect, setRect] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [animationState, setAnimationState] = useState(null);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const videoRef = useRef(null);
  const pageRef = useRef(null);

  // Handle video playback
  const playVideo = async () => {
    if (videoRef.current) {
      try {
        await videoRef.current.play();
      } catch (err) {
        console.warn('Video play failed:', err);
      }
    }
  };

  // Handle video pause
  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  // Handle mute toggle
  const toggleMute = async () => {
    if (videoRef.current) {
      const wasMuted = isVideoMuted;
      setIsVideoMuted(!wasMuted);
      videoRef.current.muted = !wasMuted;
    }
  };

  useEffect(() => {
    const savedRect = sessionStorage.getItem('projectCardRect');
    if (savedRect) {
      try {
        const parsedRect = JSON.parse(savedRect);
        setRect(parsedRect);
        setAnimationState('fromCard');
        sessionStorage.removeItem('projectCardRect');
      } catch (e) {
        console.error('Error parsing rect:', e);
        setAnimationState('direct');
      }
    } else {
      setAnimationState('direct');
    }
    setAnimationStarted(true);
  }, []);

  useEffect(() => {
    if (project && project.backgroundColor) {
      setBackgroundColor(project.backgroundColor);
    }
  }, [project]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play().then(() => {
      }).catch(error => {
        console.error('Error playing video:', error);
      });
    }

    return () => {
      if (video) {
        video.pause();
      }
    };
  }, []);

  useEffect(() => {
    if (pageRef.current && !loading && project) {
      const interpolateColor = (startColor, endColor, progress) => {
        const start = hexToRgb(startColor);
        const end = hexToRgb(endColor);
        
        const r = Math.round(start.r + (end.r - start.r) * progress);
        const g = Math.round(start.g + (end.g - start.g) * progress);
        const b = Math.round(start.b + (end.b - start.b) * progress);
        
        return `rgb(${r}, ${g}, ${b})`;
      };

      const hexToRgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : { r: 0, g: 0, b: 0 };
      };

      ScrollTrigger.create({
        trigger: pageRef.current,
        start: "500 top",
        end: "100vh top",
        scrub: false,
        onEnter: () => {
          const elements = document.querySelectorAll('[data-color-transition]');
          elements.forEach(el => {
            if (el) {
              gsap.to(el, {
                duration: 0.3,
                ease: "power2.inOut",
                onUpdate: function() {
                  const progress = this.progress();
                  const bgColor = interpolateColor(project.backgroundColor || '#000000', '#ffffff', progress);
                  const txtColor = interpolateColor('#ffffff', '#000000', progress);
                  el.style.setProperty('--background-color', bgColor);
                  el.style.setProperty('--text-color', txtColor);
                  el.style.setProperty('--opacity', progress);
                }
              });
            }
          });
        },
        onLeaveBack: () => {
          const elements = document.querySelectorAll('[data-color-transition]');
          elements.forEach(el => {
            if (el) {
              gsap.to(el, {
                duration: 0.3,
                ease: "power2.inOut",
                onUpdate: function() {
                  const progress = 1 - this.progress();
                  const bgColor = interpolateColor(project.backgroundColor || '#000000', '#ffffff', progress);
                  const txtColor = interpolateColor('#ffffff', '#000000', progress);
                  el.style.setProperty('--background-color', bgColor);
                  el.style.setProperty('--text-color', txtColor);
                  el.style.setProperty('--opacity', progress);
                }
              });
            }
          });
        },
        invalidateOnRefresh: true
      });
    }

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, [loading, project]);

  if (loading) {
    return (
      <>
        <AnimatedBackground $backgroundColor="rgba(255, 255, 255, 0)" />
        <LoadingContainer>
          <LoadingText>Loading...</LoadingText>
        </LoadingContainer>
      </>
    );
  }

  if (error || !project) {
    return (
      <>
        <AnimatedBackground $backgroundColor="rgba(255, 255, 255, 0)" />
        <ErrorContainer>
          <ErrorText>Project not found</ErrorText>
        </ErrorContainer>
      </>
    );
  }

  const startPosition = {
    x: rect ? rect.x : window.innerWidth / 2,
    y: rect ? rect.y : window.innerHeight / 2,
    fontSize: '24px'
  };

  const subtitleStartPosition = {
    x: rect ? rect.x : window.innerWidth / 2,
    y: (rect ? rect.y : window.innerHeight / 2) + 40
  };

  const videoStartPosition = {
    x: rect ? rect.x : window.innerWidth / 2,
    y: rect ? rect.y : window.innerHeight / 2,
    scale: rect ? rect.width / 300 : 0.5
  };

  const cardRect = rect;

  return (
    <PageContainer ref={pageRef}>
      <AnimatedBackground
        className="animated-background"
        $started={animationStarted}
        $cardRect={cardRect}
        $backgroundColor={backgroundColor}
      />
      <ScrollBackground 
        data-color-transition
      />
      <AnimatedContainer data-color-transition>
        <Content data-color-transition>
          <Title $startPosition={startPosition} data-color-transition>
            {project.title}
          </Title>
          <Description id="projectDescription" $startPosition={subtitleStartPosition} data-color-transition>
            {project.description}
          </Description>
          <VideoContainer 
            id="projectVideo" 
            $startPosition={videoStartPosition} 
            $animate={!!videoStartPosition}
          >
            {project.pageVideo && (
              <>
                <video
                  ref={videoRef}
                  muted={isVideoMuted}
                  loop
                  playsInline
                  autoPlay
                  src={project.pageVideo}
                  style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                  onClick={() => {
                    if (videoRef.current) {
                      if (document.fullscreenElement) {
                        document.exitFullscreen();
                        videoRef.current.muted = true;
                        setIsVideoMuted(true);
                      } else {
                        videoRef.current.requestFullscreen().then(() => {
                          videoRef.current.muted = false;
                          setIsVideoMuted(false);
                        });
                      }
                    }
                  }}
                />
                <MuteButton onClick={toggleMute}>
                  {isVideoMuted ? (
                    <svg viewBox="0 0 24 24">
                      <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z"/>
                    </svg>
                  ) : (
                    <svg viewBox="0 0 24 24">
                      <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/>
                    </svg>
                  )}
                </MuteButton>
              </>
            )}
          </VideoContainer>

          <ProjectDetails>
            <ProjectSection>
              <SectionTitle data-color-transition>About the Project</SectionTitle>
              <SectionText data-color-transition>
                {typeof project.fullDescription === 'string' 
                  ? project.fullDescription.replace(/<\/?p>/g, '')
                  : project.fullDescription}
              </SectionText>
            </ProjectSection>
            <ProjectSection>
              <SectionTitle data-color-transition>Categories</SectionTitle>
              {project.category && (
                <Categories data-color-transition>
                  {project.category.split(' ').map((tag, index) => (
                    <Category key={index} data-color-transition>{tag}</Category>
                  ))}
                </Categories>
              )}
            </ProjectSection>
          </ProjectDetails>

          {project.images && project.images.length > 0 && (
            <ProjectGallery images={project.images.map(img => img.url)} />
          )}

          <ProjectOutcome data-color-transition>
            <ProjectOutcomeTitle>Final Results</ProjectOutcomeTitle>
            <ProjectOutcomeContent>{project.resultText}</ProjectOutcomeContent>
          </ProjectOutcome>
          <CTAWrapper>
            <CTA />
          </CTAWrapper>
        </Content>
      </AnimatedContainer>
    </PageContainer>
  );
}

export default ActualProjectPage;

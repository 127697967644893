import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const PageWrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #121212;
`;

export const PageTransition = ({ children }) => {
  return (
    <PageWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </PageWrapper>
  );
};

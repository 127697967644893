import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Import FontAwesome CSS
import App from './App';
import reportWebVitals from './reportWebVitals';

// Suppress ResizeObserver loop limit exceeded error
const resizeObserverLoopErrRe = /^ResizeObserver loop limit exceeded/;
const originalError = console.error;
console.error = (...args) => {
  if (args[0] && resizeObserverLoopErrRe.test(args[0])) {
    return;
  }
  originalError.call(console, ...args);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

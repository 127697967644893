import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { PageTransition } from '../../components/PageTransition';
import CTA from '../../components/CTA';
import PageTitle from '../../components/PageTitle';

const Container = styled.div`
  background: #050411;
  color: #ffffff;
  min-height: 100vh;
  padding: 250px 5vw;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const Header = styled.div`
  margin-bottom: 120px;
  max-width: 900px;
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  line-height: 1.6;
  color: #888;
  margin-bottom: 60px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  margin-top: 80px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const Service = styled(motion.div)`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  padding: 40px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);

  &:hover {
    background: rgba(255, 255, 255, 0.04);
    transform: translateY(-5px);
  }
`;

const ServiceTitle = styled.h3`
  font-size: 32px;
  margin-bottom: 1rem;
  font-weight: 900;
  font-family: var(--primary-font);
`;

const ServiceDescription = styled.p`
  color: #888;
  line-height: 1.6;
  font-size: 1rem;
`;

const StatsSection = styled.div`
  margin: 120px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
`;

const StatItem = styled(motion.div)`
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 10px;
  background: linear-gradient(45deg, #fff, #888);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StatLabel = styled.div`
  color: #888;
  font-size: 1rem;
`;

const ProcessSection = styled.div`
  margin: 120px 0;
`;

const ProcessTitle = styled(motion.h2)`
  font-size: 3rem;
  margin-bottom: 80px;
  text-align: left;
  background: linear-gradient(to right, #fff 0%, #888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ProcessGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const ProcessStep = styled(motion.div)`
  text-align: left;
  padding: 0;
`;

const StepNumber = styled.div`
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
  background: linear-gradient(45deg, #666, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StepTitle = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #fff;
`;

const StepDescription = styled.p`
  color: #888;
  line-height: 1.6;
`;

const DigitalSolutions = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.43, 0.13, 0.23, 0.96]
      }
    }
  };

  return (
    <PageTransition>
      <Container>
        <Content>
          <Header>
            <PageTitle delay={0.2}>Digital Solutions</PageTitle>
            
            <Subtitle
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              We create innovative digital solutions that transform businesses and enhance user experiences across all platforms.
            </Subtitle>
          </Header>

          <Grid
            as={motion.div}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-laptop-code"></i>
                Web Development
              </ServiceTitle>
              <ServiceDescription>
                Build modern, responsive websites that deliver exceptional user experiences. Our solutions are optimized for performance and scalability.
              </ServiceDescription>
            </Service>

            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-mobile-alt"></i>
                Mobile Apps
              </ServiceTitle>
              <ServiceDescription>
                Develop native and cross-platform mobile applications that engage users and drive business growth. We focus on intuitive interfaces and smooth performance.
              </ServiceDescription>
            </Service>

            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-shopping-cart"></i>
                E-Commerce
              </ServiceTitle>
              <ServiceDescription>
                Create powerful online stores that convert visitors into customers. We implement secure payment systems and optimize the shopping experience.
              </ServiceDescription>
            </Service>

            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-chart-bar"></i>
                Digital Analytics
              </ServiceTitle>
              <ServiceDescription>
                Implement comprehensive analytics solutions to track performance and user behavior. Make data-driven decisions to improve your digital presence.
              </ServiceDescription>
            </Service>
          </Grid>

          <StatsSection>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <StatNumber>400+</StatNumber>
              <StatLabel>Projects Delivered</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <StatNumber>99%</StatNumber>
              <StatLabel>Uptime Guaranteed</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <StatNumber>8+</StatNumber>
              <StatLabel>Years Experience</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
            >
              <StatNumber>1M+</StatNumber>
              <StatLabel>Users Served</StatLabel>
            </StatItem>
          </StatsSection>

          <ProcessSection>
            <ProcessTitle
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              Our Development Process
            </ProcessTitle>
            <ProcessGrid>
              <ProcessStep
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                <StepNumber>01</StepNumber>
                <StepTitle>Planning & Analysis</StepTitle>
                <StepDescription>
                  We analyze your requirements and create a detailed project roadmap. Our team identifies the best technologies and approaches for your needs.
                </StepDescription>
              </ProcessStep>

              <ProcessStep
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                <StepNumber>02</StepNumber>
                <StepTitle>Development & Testing</StepTitle>
                <StepDescription>
                  Our developers build your solution using modern technologies and best practices. Rigorous testing ensures quality and reliability.
                </StepDescription>
              </ProcessStep>

              <ProcessStep
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <StepNumber>03</StepNumber>
                <StepTitle>Deployment & Support</StepTitle>
                <StepDescription>
                  We handle the deployment process and provide ongoing support to ensure your digital solution performs optimally.
                </StepDescription>
              </ProcessStep>
            </ProcessGrid>
          </ProcessSection>

          <CTA 
            title="Ready to Transform Your Digital Presence?"
            description="Let's build innovative digital solutions that will take your business to the next level."
            buttonText="Start Your Digital Journey"
          />
        </Content>
      </Container>
    </PageTransition>
  );
};

export default DigitalSolutions;

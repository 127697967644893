import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { PageTransition } from '../../components/PageTransition';
import CTA from '../../components/CTA';
import PageTitle from '../../components/PageTitle';

const Container = styled.div`
  background: #050411;
  color: #ffffff;
  min-height: 100vh;
  padding: 250px 5vw;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const Header = styled.div`
  margin-bottom: 120px;
  max-width: 900px;
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  color: #888;
  line-height: 1.6;
  margin-bottom: 60px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  margin-top: 80px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const Service = styled(motion.div)`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  padding: 40px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);

  &:hover {
    background: rgba(255, 255, 255, 0.04);
    transform: translateY(-5px);
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;
  font-weight: 900;

  i {
    font-size: 1.6rem;
    color: #888;
  }
`;

const ServiceDescription = styled.p`
  color: #888;
  line-height: 1.6;
  font-size: 1rem;
`;

const StatsSection = styled.div`
  margin: 120px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
`;

const StatItem = styled(motion.div)`
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 10px;
  background: linear-gradient(45deg, #fff, #888);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StatLabel = styled.div`
  color: #888;
  font-size: 1rem;
`;

const ProcessSection = styled.div`
  margin: 120px 0;
`;

const ProcessTitle = styled(motion.h2)`
  font-size: 3rem;
  margin-bottom: 80px;
  text-align: left;
  background: linear-gradient(to right, #fff 0%, #888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ProcessGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const ProcessStep = styled(motion.div)`
  text-align: left;
  padding: 0;
`;

const StepNumber = styled.div`
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
  background: linear-gradient(45deg, #666, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StepTitle = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 900;
`;

const StepDescription = styled.p`
  color: #888;
  line-height: 1.6;
`;

const VideoProduction = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.43, 0.13, 0.23, 0.96]
      }
    }
  };

  return (
    <PageTransition>
      <Container>
        <Content>
          <Header>
            <PageTitle delay={0.2}>Video Production</PageTitle>
            
            <Subtitle
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              We create compelling video content that tells your story and engages your audience across all platforms.
            </Subtitle>
          </Header>

          <Grid
            as={motion.div}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-film"></i>
                Commercial Production
              </ServiceTitle>
              <ServiceDescription>
                Create impactful commercials that showcase your brand's unique value proposition. From concept to final delivery, we handle every aspect of production.
              </ServiceDescription>
            </Service>

            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-video"></i>
                Corporate Videos
              </ServiceTitle>
              <ServiceDescription>
                Professional corporate videos that communicate your message clearly and effectively. Perfect for training, presentations, and company culture.
              </ServiceDescription>
            </Service>

            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-camera"></i>
                Social Media Content
              </ServiceTitle>
              <ServiceDescription>
                Engaging video content optimized for social media platforms. Drive engagement and grow your online presence with compelling storytelling.
              </ServiceDescription>
            </Service>

            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-edit"></i>
                Post-Production
              </ServiceTitle>
              <ServiceDescription>
                Expert editing, color grading, and motion graphics to enhance your video content. We ensure your final product meets the highest quality standards.
              </ServiceDescription>
            </Service>
          </Grid>

          <StatsSection>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <StatNumber>500+</StatNumber>
              <StatLabel>Videos Produced</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <StatNumber>95%</StatNumber>
              <StatLabel>Client Satisfaction</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <StatNumber>12+</StatNumber>
              <StatLabel>Years Experience</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
            >
              <StatNumber>50M+</StatNumber>
              <StatLabel>Views Generated</StatLabel>
            </StatItem>
          </StatsSection>

          <ProcessSection>
            <ProcessTitle
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              Our Production Process
            </ProcessTitle>
            <ProcessGrid>
              <ProcessStep
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                <StepNumber>01</StepNumber>
                <StepTitle>Pre-Production</StepTitle>
                <StepDescription>
                  We plan every detail of your video project, from concept development and scriptwriting to location scouting and scheduling.
                </StepDescription>
              </ProcessStep>

              <ProcessStep
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                <StepNumber>02</StepNumber>
                <StepTitle>Production</StepTitle>
                <StepDescription>
                  Our experienced crew executes the shoot with precision, using state-of-the-art equipment to capture high-quality footage.
                </StepDescription>
              </ProcessStep>

              <ProcessStep
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <StepNumber>03</StepNumber>
                <StepTitle>Post-Production</StepTitle>
                <StepDescription>
                  We transform raw footage into polished content through professional editing, color grading, and sound design.
                </StepDescription>
              </ProcessStep>
            </ProcessGrid>
          </ProcessSection>
          <CTA 
            title="Ready to Tell Your Story?"
            description="Let's create compelling video content that captures your audience's attention and delivers your message effectively."
            buttonText="Start Your Video Project"
          />
        </Content>
      </Container>
    </PageTransition>
  );
};

export default VideoProduction;

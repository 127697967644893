import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { PageTransition } from '../components/PageTransition';
import CTA from '../components/CTA';
import PageTitle from '../components/PageTitle';

const ServicesContainer = styled.div`
  background: #050411;
  color: #ffffff;
  min-height: 100vh;
  padding: 250px 5vw;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const ServicesContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  margin-top: 80px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const ServiceCategory = styled(motion.div)`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  padding: 40px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);

  &:hover {
    background: rgba(255, 255, 255, 0.04);
    transform: translateY(-5px);
  }
`;

const CategoryTitle = styled.h3`
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 900;
  color: #fff;
  font-family: var(--primary-font);
`;

const CategoryDescription = styled.p`
  font-size: 24px;
  color: #888;
  line-height: 1.6;
  margin-bottom: 30px;
`;

const ServicesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ServiceItem = styled.li`
  font-size: 18px;
  color: #888;
  margin-bottom: 12px;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  width: fit-content;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    right: 0;
    background: #fff;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #fff;

    &::after {
      width: 100%;
      left: 0;
      right: auto;
    }
  }
`;

const Services = () => {
  return (
    <PageTransition>
      <ServicesContainer>
        <ServicesContent>
          <PageTitle delay={0.2}>Our Services</PageTitle>
          
          <ServicesGrid>
            <ServiceCategory>
              <CategoryTitle>Creative Direction</CategoryTitle>
              <CategoryDescription>Innovative solutions for products and brand experiences.</CategoryDescription>
              <ServicesList>
                <ServiceItem>3D Design & Visualization</ServiceItem>
                <ServiceItem>Brand Identity</ServiceItem>
                <ServiceItem>Motion & Visual Effects</ServiceItem>
              </ServicesList>
            </ServiceCategory>

            <ServiceCategory>
              <CategoryTitle>Video Production</CategoryTitle>
              <CategoryDescription>High-quality video production for commercial and advertising.</CategoryDescription>
              <ServicesList>
                <ServiceItem>Commercial Production</ServiceItem>
                <ServiceItem>Content Production</ServiceItem>
                <ServiceItem>Post Production</ServiceItem>
              </ServicesList>
            </ServiceCategory>

            <ServiceCategory>
              <CategoryTitle>Marketing & Social</CategoryTitle>
              <CategoryDescription>Comprehensive social media strategy and management.</CategoryDescription>
              <ServicesList>
                <ServiceItem>Social Media Management</ServiceItem>
                <ServiceItem>Digital Marketing</ServiceItem>
                <ServiceItem>Campaign Management</ServiceItem>
              </ServicesList>
            </ServiceCategory>

            <ServiceCategory>
              <CategoryTitle>Digital Solutions</CategoryTitle>
              <CategoryDescription>Custom web solutions for modern businesses.</CategoryDescription>
              <ServicesList>
                <ServiceItem>Web Development</ServiceItem>
                <ServiceItem>UI/UX Design</ServiceItem>
                <ServiceItem>Technical Solutions</ServiceItem>
              </ServicesList>
            </ServiceCategory>

            <ServiceCategory>
              <CategoryTitle>Animation Studio</CategoryTitle>
              <CategoryDescription>Cutting-edge animation and motion graphics solutions.</CategoryDescription>
              <ServicesList>
                <ServiceItem>2D & 3D Animation</ServiceItem>
                <ServiceItem>Character Animation</ServiceItem>
                <ServiceItem>Explainer Videos</ServiceItem>
              </ServicesList>
            </ServiceCategory>

            <ServiceCategory>
              <CategoryTitle>Interactive Design</CategoryTitle>
              <CategoryDescription>Immersive digital experiences and interactive solutions.</CategoryDescription>
              <ServicesList>
                <ServiceItem>AR/VR Experiences</ServiceItem>
                <ServiceItem>Interactive Installations</ServiceItem>
                <ServiceItem>Digital Exhibitions</ServiceItem>
              </ServicesList>
            </ServiceCategory>

            <ServiceCategory>
              <CategoryTitle>Content Strategy</CategoryTitle>
              <CategoryDescription>Strategic content planning and creation for all platforms.</CategoryDescription>
              <ServicesList>
                <ServiceItem>Content Planning</ServiceItem>
                <ServiceItem>Copywriting</ServiceItem>
                <ServiceItem>Content Analytics</ServiceItem>
              </ServicesList>
            </ServiceCategory>

            <ServiceCategory>
              <CategoryTitle>Video & Photography</CategoryTitle>
              <CategoryDescription>Professional video and photo production for all your creative needs.</CategoryDescription>
              <ServicesList>
                <ServiceItem>Commercial Photography</ServiceItem>
                <ServiceItem>Product Photography</ServiceItem>
                <ServiceItem>Event Photography</ServiceItem>
              </ServicesList>
            </ServiceCategory>

            <ServiceCategory>
              <CategoryTitle>Audio Production</CategoryTitle>
              <CategoryDescription>Professional audio solutions for media and advertising.</CategoryDescription>
              <ServicesList>
                <ServiceItem>Voice Over Production</ServiceItem>
                <ServiceItem>Sound Design</ServiceItem>
                <ServiceItem>Audio Post Production</ServiceItem>
              </ServicesList>
            </ServiceCategory>

            <ServiceCategory>
              <CategoryTitle>Creative Consulting</CategoryTitle>
              <CategoryDescription>Strategic creative guidance and project development.</CategoryDescription>
              <ServicesList>
                <ServiceItem>Creative Strategy</ServiceItem>
                <ServiceItem>Project Management</ServiceItem>
                <ServiceItem>Creative Workshops</ServiceItem>
              </ServicesList>
            </ServiceCategory>
          </ServicesGrid>

          <CTA 
            title="Let's Create Something Amazing"
            description="Transform your ideas into reality with our expertise"
            buttonText="Start Your Project"
          />
        </ServicesContent>
      </ServicesContainer>
    </PageTransition>
  );
};

export default Services;

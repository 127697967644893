import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import CTA from './CTA';

const CookiesContainer = styled.div`
  padding: 280px 5% 100px;
  background: var(--background);
  color: #fff;
  min-height: 100vh;
`;

const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

const Title = styled(motion.h1)`
  font-size: clamp(4rem, 8vw, 6rem);
  margin-bottom: 100px;
  font-weight: 750;
  line-height: 1.2;
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0.7) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 1200px) {
    font-size: clamp(4rem, 8vw, 6rem);
  }

  @media (max-width: 768px) {
    font-size: clamp(4rem, 7vw, 5rem);
  }

  @media (max-width: 480px) {
    font-size: clamp(3rem, 6vw, 4rem);
  }
`;

const Section = styled(motion.div)`
  margin-bottom: 120px;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #fff;
  font-weight: 600;
`;

const Text = styled.p`
  font-size: 1.5rem;
  line-height: 1.6;
  color: #888;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Cookies = () => {
  return (
    <CookiesContainer>
      <Content>
        <Title
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 1,
            ease: [0.19, 1, 0.22, 1],
          }}
        >
          Cookies Policy
        </Title>

        <Section
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <SectionTitle>What Are Cookies</SectionTitle>
          <Text>
            Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the service or a third-party to recognize you and make your next visit easier and more useful to you.
          </Text>
        </Section>

        <Section
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <SectionTitle>How We Use Cookies</SectionTitle>
          <Text>
            We use cookies for various purposes including understanding and saving user preferences for future visits, keeping track of advertisements, and compiling aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
          </Text>
        </Section>

        <Section
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.8 }}
        >
          <SectionTitle>Disabling Cookies</SectionTitle>
          <Text>
            You can prevent the setting of cookies by adjusting the settings on your browser. Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of this site.
          </Text>
        </Section>
        <CTA />
      </Content>
    </CookiesContainer>
  );
};

export default Cookies;

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Navigation from './components/Navigation';
import Home from './components/Home';
import ActualProjectPage from './components/ActualProjectPage';
import CustomCursor from './components/CustomCursor';
import Loader from './components/Loader';
import HireUs from './components/HireUs';
import About from './components/About';
import Contact from './components/Contact';
import CreativeDirection from './pages/services/CreativeDirection';
import VideoProduction from './pages/services/VideoProduction';
import BrandIdentity from './pages/services/BrandIdentity';
import DigitalSolutions from './pages/services/DigitalSolutions';
import Services from './pages/Services';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Cookies from './components/Cookies';
import FAQ from './components/FAQ';
import GoogleAnalytics from './components/GoogleAnalytics';
import Hotjar from './components/Hotjar';
import './App.css';

// Add Font Awesome icons to library
library.add(fab, fas);

function App() {
  const [loadingComplete, setLoadingComplete] = useState(false);

  const handleLoadingComplete = () => {
    setLoadingComplete(true);
  };

  return (
    <Router>
      <ScrollToTop />
      <GoogleAnalytics />
      <Hotjar />
      <div className="App">
        {!loadingComplete && <Loader onComplete={handleLoadingComplete} />}
        {loadingComplete && (
          <>
            <CustomCursor />
            <Navigation />
            <main className="content-wrapper">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/project/:slug" element={<ActualProjectPage />} />
                <Route path="/hire-us" element={<HireUs />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/creative-direction" element={<CreativeDirection />} />
                <Route path="/services/video-production" element={<VideoProduction />} />
                <Route path="/services/brand-identity" element={<BrandIdentity />} />
                <Route path="/services/digital-solutions" element={<DigitalSolutions />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/cookies" element={<Cookies />} />
                <Route path="/faq" element={<FAQ />} />
              </Routes>
            </main>
            <Footer />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { PageTransition } from './PageTransition';
import PageTitle from './PageTitle';
import CTA from './CTA';

const AboutContainer = styled.div`
  background-color: #050411;
  color: #ffffff;
  min-height: 100vh;
  padding: 250px 5vw;
  font-family: var(--primary-font);
`;

const AboutContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const AboutText = styled(motion.p)`
  font-size: 24px;
  line-height: 1.8;
  margin-bottom: 100px;
  color: #888;
  max-width: 800px;
  text-align: left;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  margin-top: 80px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const GridItem = styled(motion.div)`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  padding: 40px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);

  &:hover {
    background: rgba(255, 255, 255, 0.04);
    transform: translateY(-5px);
  }
`;

const ItemTitle = styled.h3`
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 900;
  color: #fff;
  font-family: var(--primary-font);
`;

const ItemText = styled.p`
  font-size: 24px;
  color: #888;
  line-height: 1.6;
  text-align: left;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  margin: 120px 0;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
`;

const StatItem = styled.div`
  text-align: left;
`;

const StatNumber = styled.div`
  font-size: 48px;
  font-weight: 900;
  color: #fff;
  margin-bottom: 10px;
`;

const StatLabel = styled.div`
  font-size: 18px;
  color: #888;
  line-height: 1.4;
`;

const About = () => {
  return (
    <PageTransition>
      <AboutContainer>
        <AboutContent>
          <PageTitle delay={0.2}>Your Complete Creative Partner</PageTitle>
          
          <AboutText
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            We are a creative powerhouse, bringing together expertise in design, technology, and strategy to transform your vision into reality.
          </AboutText>

          <StatsGrid>
            <StatItem>
              <StatNumber>1000+</StatNumber>
              <StatLabel>Projects Completed</StatLabel>
            </StatItem>
            <StatItem>
              <StatNumber>15+</StatNumber>
              <StatLabel>Years Experience</StatLabel>
            </StatItem>
            <StatItem>
              <StatNumber>100%</StatNumber>
              <StatLabel>Client Satisfaction</StatLabel>
            </StatItem>
            <StatItem>
              <StatNumber>99%</StatNumber>
              <StatLabel> Success Rate</StatLabel>
            </StatItem>
          </StatsGrid>

          <Grid>
            <GridItem>
              <ItemTitle>Technical Excellence</ItemTitle>
              <ItemText>
                From mobile apps to 3D visualizations, our team of experts delivers cutting-edge solutions using the latest technologies and industry best practices.
              </ItemText>
            </GridItem>
            <GridItem>
              <ItemTitle>Creative Innovation</ItemTitle>
              <ItemText>
                Our creative team pushes boundaries to create unique, engaging content that captures attention and delivers results across all platforms.
              </ItemText>
            </GridItem>
            <GridItem>
              <ItemTitle>Strategic Approach</ItemTitle>
              <ItemText>
                We combine creativity with strategic thinking to ensure every project aligns with your business goals and delivers measurable results.
              </ItemText>
            </GridItem>
            <GridItem>
              <ItemTitle>Client Focus</ItemTitle>
              <ItemText>
                Your success is our priority. We work closely with you to understand your needs and deliver solutions that exceed expectations.
              </ItemText>
            </GridItem>
          </Grid>

          <CTA 
            title="Ready to Start Your Project?"
            description="Let's create something extraordinary together"
            buttonText="Get in Touch"
          />
        </AboutContent>
      </AboutContainer>
    </PageTransition>
  );
};

export default About;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import CTA from './CTA';

const TermsContainer = styled.div`
  padding: 280px 5% 100px;
  background: var(--background);
  color: #fff;
  min-height: 100vh;
`;

const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

const Title = styled(motion.h1)`
  font-size: clamp(4rem, 8vw, 6rem);
  margin-bottom: 100px;
  font-weight: 750;
  line-height: 1.2;
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0.7) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 1200px) {
    font-size: clamp(4rem, 8vw, 6rem);
  }

  @media (max-width: 768px) {
    font-size: clamp(4rem, 7vw, 5rem);
  }

  @media (max-width: 480px) {
    font-size: clamp(3rem, 6vw, 4rem);
  }
`;

const Section = styled(motion.div)`
  margin-bottom: 120px;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #fff;
  font-weight: 600;
`;

const Text = styled.p`
  font-size: 1.5rem;
  line-height: 1.6;
  color: #888;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Terms = () => {
  return (
    <TermsContainer>
      <Content>
        <Title
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 1,
            ease: [0.19, 1, 0.22, 1],
          }}
        >
          Terms of Service
        </Title>

        <Section
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <SectionTitle>Agreement to Terms</SectionTitle>
          <Text>
            By accessing our website and services, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing our services.
          </Text>
        </Section>

        <Section
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <SectionTitle>Services Description</SectionTitle>
          <Text>
            We provide digital services including but not limited to web development, digital marketing, and brand strategy consulting. Our website serves as an informational platform to showcase our services and expertise. We do not sell any physical products through this website. All services are provided on a project basis, subject to separate service agreements and consultations with our team.
          </Text>
        </Section>

        <Section
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.8 }}
        >
          <SectionTitle>Intellectual Property</SectionTitle>
          <Text>
            All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of our agency and is protected by intellectual property laws. The content is provided for informational purposes only and may not be copied, modified, or distributed without our express written permission.
          </Text>
        </Section>

        <Section
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1 }}
        >
          <SectionTitle>Disclaimer</SectionTitle>
          <Text>
            The information provided on our website is for general informational purposes only. While we strive to keep the information up to date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the services, information, or related graphics contained on the website for any purpose.
          </Text>
        </Section>
        <CTA />
      </Content>
    </TermsContainer>
  );
};

export default Terms;

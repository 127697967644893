import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Initialize Google Analytics
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-WYZFNR67GW';
    document.head.appendChild(script1);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-WYZFNR67GW');

    // Track page views
    const trackPageView = () => {
      if (window.gtag) {
        window.gtag('config', 'G-WYZFNR67GW', {
          page_path: location.pathname + location.search,
        });
      }
    };

    // Track initial page view
    trackPageView();

    // Track page views on route changes
    return () => {
      document.head.removeChild(script1);
    };
  }, [location]);

  return null;
};

export default GoogleAnalytics;

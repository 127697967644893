import axios from 'axios';

const WP_API_URL = 'https://all4.agency/headless/wp-json/wp/v2';

// Create axios instance with default config
const api = axios.create({
  baseURL: WP_API_URL,
  timeout: 10000,
});

// Cache for API responses
const cache = new Map();
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const fetchWithCache = async (key, fetchFunction) => {
  const cached = cache.get(key);
  if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
    return cached.data;
  }

  const data = await fetchFunction();
  cache.set(key, { data, timestamp: Date.now() });
  return data;
};

// Transform WordPress data to match your format
const transformProject = (project) => {
  const fullDescription = project.acf.full_description;
  // Clean the description if it's a string
  const cleanedDescription = typeof fullDescription === 'string' 
    ? fullDescription.replace(/<\/?p>/g, '').trim()
    : fullDescription;

  return {
    id: project.id,
    slug: project.slug,  // استخدام الـ slug الأساسي للمنشور
    title: project.title.rendered,
    description: project.acf.description,
    cardVideo: project.acf.card_video,
    pageVideo: project.acf.page_video,
    thumbnail: project.acf.thumbnail,
    backgroundColor: project.acf.background_color,
    fullDescription: cleanedDescription,
    client: project.acf.client,
    year: project.acf.year,
    category: project.acf.category,
    categories: project.acf.categories?.map(cat => cat.name) || [],
    resultText: project.acf.result_text,
    images: project.acf.images || []
  };
};

// API functions
export const wordpressApi = {
  // Fetch all projects
  getAllProjects: async () => {
    return fetchWithCache('all_projects', async () => {
      try {
        const { data } = await api.get('/project?_embed&per_page=100');
        return data.map(transformProject);
      } catch (error) {
        console.error('Error fetching projects:', error);
        throw new Error('Failed to fetch projects');
      }
    });
  },

  // Fetch single project
  getProject: async (slug) => {
    return fetchWithCache(`project_${slug}`, async () => {
      try {
        const { data } = await api.get(`/project?slug=${slug}&_embed`);  // استخدام الـ slug الأساسي
        if (!data.length) throw new Error('Project not found');
        return transformProject(data[0]);
      } catch (error) {
        console.error(`Error fetching project ${slug}:`, error);
        throw new Error('Failed to fetch project');
      }
    });
  },

  // Clear cache
  clearCache: () => {
    cache.clear();
  }
};

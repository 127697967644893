import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import CTA from './CTA';

const PrivacyContainer = styled.div`
  padding: 280px 5% 100px;
  background: var(--background);
  color: #fff;
  min-height: 100vh;
`;

const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

const Title = styled(motion.h1)`
  font-size: clamp(4rem, 8vw, 6rem);
  margin-bottom: 100px;
  font-weight: 750;
  line-height: 1.2;
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0.7) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 1200px) {
    font-size: clamp(4rem, 8vw, 6rem);
  }

  @media (max-width: 768px) {
    font-size: clamp(4rem, 7vw, 5rem);
  }

  @media (max-width: 480px) {
    font-size: clamp(3rem, 6vw, 4rem);
  }
`;

const Section = styled(motion.div)`
  margin-bottom: 120px;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #fff;
  font-weight: 600;
`;

const Text = styled.p`
  font-size: 1.5rem;
  line-height: 1.6;
  color: #888;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Privacy = () => {
  return (
    <PrivacyContainer>
      <Content>
        <Title
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 1,
            ease: [0.19, 1, 0.22, 1],
          }}
        >
          Privacy Policy
        </Title>

        <Section
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <SectionTitle>Information We Collect</SectionTitle>
          <Text>
            We collect information that you provide directly to us, including when you fill out forms, communicate with us, or interact with our services. This may include your name, email address, phone number, and any other information you choose to provide.
          </Text>
        </Section>

        <Section
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <SectionTitle>How We Use Your Information</SectionTitle>
          <Text>
            We use the information we collect to provide, maintain, and improve our services, communicate with you, and comply with legal obligations. We may also use your information to send you updates about our services, promotional materials, and other communications.
          </Text>
        </Section>

        <Section
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.8 }}
        >
          <SectionTitle>Information Sharing</SectionTitle>
          <Text>
            We do not sell, trade, or otherwise transfer your personal information to outside parties. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
          </Text>
        </Section>
        <CTA />
      </Content>
    </PrivacyContainer>
  );
};

export default Privacy;

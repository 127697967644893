import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { PageTransition } from '../../components/PageTransition';
import CTA from '../../components/CTA';
import PageTitle from '../../components/PageTitle';

const Container = styled.div`
  background: #050411;
  color: #ffffff;
  min-height: 100vh;
  padding: 250px 5vw;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const Header = styled.div`
  margin-bottom: 120px;
  max-width: 900px;
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  color: #888;
  line-height: 1.6;
  margin-bottom: 60px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  margin-top: 80px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const Service = styled(motion.div)`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  padding: 40px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);

  &:hover {
    background: rgba(255, 255, 255, 0.04);
    transform: translateY(-5px);
  }
`;

const ServiceTitle = styled.h3`
  font-size: 32px;
  margin-bottom: 1rem;
  font-weight: 900;
  font-family: var(--primary-font);
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;

  i {
    font-size: 1.6rem;
    color: #888;
  }
`;

const ServiceDescription = styled.p`
  color: #888;
  line-height: 1.6;
  font-size: 1rem;
`;

const StatsSection = styled.div`
  margin: 120px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
`;

const StatItem = styled(motion.div)`
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 10px;
  background: linear-gradient(45deg, #fff, #888);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StatLabel = styled.div`
  color: #888;
  font-size: 1rem;
`;

const ProcessSection = styled.div`
  margin: 120px 0;
`;

const ProcessTitle = styled(motion.h2)`
  font-size: 3rem;
  margin-bottom: 80px;
  text-align: left;
  background: linear-gradient(to right, #fff 0%, #888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ProcessGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const ProcessStep = styled(motion.div)`
  text-align: left;
  padding: 0;
`;

const StepNumber = styled.div`
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
  background: linear-gradient(45deg, #666, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StepTitle = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #fff;
`;

const StepDescription = styled.p`
  color: #888;
  line-height: 1.6;
`;

const BrandIdentity = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.43, 0.13, 0.23, 0.96]
      }
    }
  };

  return (
    <PageTransition>
      <Container>
        <Content>
          <Header>
            <PageTitle delay={0.2}>Brand Identity</PageTitle>
            
            <Subtitle
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              We craft unique and memorable brand identities that resonate with your audience and stand the test of time.
            </Subtitle>
          </Header>

          <Grid
            as={motion.div}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-palette"></i>
                Visual Identity
              </ServiceTitle>
              <ServiceDescription>
                Create a distinctive visual language that sets your brand apart. We develop comprehensive brand guidelines that ensure consistency across all touchpoints.
              </ServiceDescription>
            </Service>

            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-pen-fancy"></i>
                Logo Design
              </ServiceTitle>
              <ServiceDescription>
                Design memorable logos that capture your brand's essence. Our approach combines creativity with strategic thinking to create lasting impressions.
              </ServiceDescription>
            </Service>

            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-swatchbook"></i>
                Brand Guidelines
              </ServiceTitle>
              <ServiceDescription>
                Develop comprehensive style guides that maintain brand consistency. From typography to color palettes, we define the rules that guide your brand's visual expression.
              </ServiceDescription>
            </Service>

            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-bullseye"></i>
                Brand Strategy
              </ServiceTitle>
              <ServiceDescription>
                Build a strong foundation for your brand with strategic positioning. We help define your brand's voice, values, and unique market position.
              </ServiceDescription>
            </Service>
          </Grid>

          <StatsSection>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <StatNumber>300+</StatNumber>
              <StatLabel>Brands Created</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <StatNumber>97%</StatNumber>
              <StatLabel>Client Satisfaction</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <StatNumber>10+</StatNumber>
              <StatLabel>Years Experience</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
            >
              <StatNumber>150+</StatNumber>
              <StatLabel>Active Clients</StatLabel>
            </StatItem>
          </StatsSection>

          <ProcessSection>
            <ProcessTitle
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              Our Branding Process
            </ProcessTitle>
            <ProcessGrid>
              <ProcessStep
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                <StepNumber>01</StepNumber>
                <StepTitle>Discovery & Research</StepTitle>
                <StepDescription>
                  We dive deep into your industry, audience, and competitors to understand what makes your brand unique and how to position it effectively.
                </StepDescription>
              </ProcessStep>

              <ProcessStep
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                <StepNumber>02</StepNumber>
                <StepTitle>Design & Development</StepTitle>
                <StepDescription>
                  Our creative team develops your brand's visual elements, from logo concepts to complete identity systems that align with your strategy.
                </StepDescription>
              </ProcessStep>

              <ProcessStep
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <StepNumber>03</StepNumber>
                <StepTitle>Implementation</StepTitle>
                <StepDescription>
                  We provide comprehensive guidelines and assets, ensuring your brand is consistently and effectively implemented across all platforms.
                </StepDescription>
              </ProcessStep>
            </ProcessGrid>
          </ProcessSection>
          <CTA 
            title="Ready to Build Your Brand?"
            description="Let's create a distinctive brand identity that sets you apart and resonates with your target audience."
            buttonText="Start Your Brand Journey"
          />
        </Content>
      </Container>
    </PageTransition>
  );
};

export default BrandIdentity;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { PageTransition } from '../../components/PageTransition';
import CTA from '../../components/CTA';
import PageTitle from '../../components/PageTitle';

const Container = styled.div`
  background: #050411;
  color: #ffffff;
  min-height: 100vh;
  padding: 250px 5vw;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const Header = styled.div`
  margin-bottom: 120px;
  max-width: 900px;
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  color: #888;
  line-height: 1.6;
  margin-bottom: 60px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  margin-top: 80px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const Service = styled(motion.div)`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  padding: 40px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);

  &:hover {
    background: rgba(255, 255, 255, 0.04);
    transform: translateY(-5px);
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;
  font-weight: 900;

  i {
    font-size: 1.6rem;
    color: #888;
  }
`;

const ServiceDescription = styled.p`
  color: #888;
  line-height: 1.6;
  font-size: 1rem;
`;

const StatsSection = styled.div`
  margin: 120px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
`;

const StatItem = styled(motion.div)`
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 10px;
  background: linear-gradient(45deg, #fff, #888);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StatLabel = styled.div`
  color: #888;
  font-size: 1rem;
`;

const ProcessSection = styled.div`
  margin: 120px 0;
`;

const ProcessTitle = styled(motion.h2)`
  font-size: 3rem;
  margin-bottom: 80px;
  text-align: left;
  background: linear-gradient(to right, #fff 0%, #888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ProcessGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const ProcessStep = styled(motion.div)`
  text-align: left;
  padding: 0;
`;

const StepNumber = styled.div`
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
  background: linear-gradient(45deg, #666, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StepTitle = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 900;
`;

const StepDescription = styled.p`
  color: #888;
  line-height: 1.6;
`;

const CreativeDirection = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.43, 0.13, 0.23, 0.96]
      }
    }
  };

  return (
    <PageTransition>
      <Container>
        <Content>
          <Header>
            <PageTitle delay={0.2}>Creative Direction</PageTitle>
            <Subtitle
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              We shape your vision into compelling narratives and innovative designs that captivate and inspire.
            </Subtitle>
          </Header>

          <Grid
            as={motion.div}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-lightbulb"></i>
                Strategic Ideation
              </ServiceTitle>
              <ServiceDescription>
                Transform your ideas into powerful visual concepts. We collaborate closely with you to develop unique creative strategies that align with your brand vision and objectives.
              </ServiceDescription>
            </Service>

            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-eye"></i>
                Visual Storytelling
              </ServiceTitle>
              <ServiceDescription>
                Create compelling visual narratives that resonate with your audience. Our team crafts immersive experiences that communicate your message effectively and memorably.
              </ServiceDescription>
            </Service>

            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-pencil-ruler"></i>
                Art Direction
              </ServiceTitle>
              <ServiceDescription>
                Guide the visual style of your projects with expert art direction. We ensure consistency and excellence across all creative elements, from concept to execution.
              </ServiceDescription>
            </Service>

            <Service variants={itemVariants}>
              <ServiceTitle>
                <i className="fas fa-chart-line"></i>
                Brand Strategy
              </ServiceTitle>
              <ServiceDescription>
                Develop comprehensive brand strategies that set you apart. We help position your brand effectively in the market while maintaining creative integrity and innovation.
              </ServiceDescription>
            </Service>
          </Grid>

          <StatsSection>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <StatNumber>250+</StatNumber>
              <StatLabel>Projects Completed</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <StatNumber>98%</StatNumber>
              <StatLabel>Client Satisfaction</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <StatNumber>15+</StatNumber>
              <StatLabel>Years Experience</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
            >
              <StatNumber>120+</StatNumber>
              <StatLabel>Active Clients</StatLabel>
            </StatItem>
          </StatsSection>

          <ProcessSection>
            <ProcessTitle
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              Our Creative Process
            </ProcessTitle>
            <ProcessGrid>
              <ProcessStep
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                <StepNumber>01</StepNumber>
                <StepTitle>Discovery & Strategy</StepTitle>
                <StepDescription>
                  We begin by deeply understanding your brand, objectives, and audience. Through collaborative workshops and research, we develop a strategic foundation for your creative direction.
                </StepDescription>
              </ProcessStep>

              <ProcessStep
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                <StepNumber>02</StepNumber>
                <StepTitle>Creative Development</StepTitle>
                <StepDescription>
                  Our team explores multiple creative directions, refining concepts through an iterative process that ensures we capture the essence of your brand while pushing creative boundaries.
                </StepDescription>
              </ProcessStep>

              <ProcessStep
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <StepNumber>03</StepNumber>
                <StepTitle>Implementation & Evolution</StepTitle>
                <StepDescription>
                  We bring the chosen direction to life across all touchpoints, ensuring consistency and impact. We continuously refine and adapt the creative direction based on performance and feedback.
                </StepDescription>
              </ProcessStep>
            </ProcessGrid>
          </ProcessSection>
          <CTA 
            title="Ready to Bring Your Vision to Life?"
            description="Let's collaborate to create a unique creative direction that perfectly aligns with your goals and aspirations."
            buttonText="Start Your Creative Journey"
          />
        </Content>
      </Container>
    </PageTransition>
  );
};

export default CreativeDirection;

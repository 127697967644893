import React, { useState, useEffect, useRef, Suspense } from 'react';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence, useScroll, useMotionValue, useSpring } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import CTA from './CTA';
import { useProjects } from '../hooks/useWordPress';
const Logo3D = React.lazy(() => import('./Logo3D'));

const HomeContainer = styled.div`
  background: #050411;
  color: #ffffff;
  min-height: 100vh;
  padding: 250px 5vw 250px 5vw;
  overflow: visible;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const HeroSection = styled.section`
  position: relative;
  margin: 80px auto 40px;
  overflow: visible;
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    margin: 30px auto 50px auto;
  }
`;

const HeroContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4rem;
  z-index: 2;
`;

const HeroColumns = styled.div`
  display: flex;
  width: 100%;
  gap: 4rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  flex: 0 0 850px;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media (max-width: 1024px) {
    flex: 1;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Logo3DWrapper = styled(motion.div)`
  opacity: 1;
  position: absolute;
  right: 5%;
  width: 500px;
  height: 500px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

const MainTitleWord = styled(motion.span)`
  display: inline-block;
  white-space: pre;
  margin-right: 12px;
  
  &:last-child {
    margin-right: 0;
  }
`;

const MainTitle = styled(motion.h1)`
  font-size: clamp(4rem, 8vw, 6.2rem);
  font-family: 'HelveticaNowVar', sans-serif;
  font-weight: 750;
  line-height: 1.2;
  color: rgb(255, 255, 255);
  display: flex;
  flex-wrap: wrap;
  max-width: 850px;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    font-size: clamp(3rem, 7vw, 4.5rem);
    line-height: 1.1;
  }
`;

const subtitles = [
  "We design your perfect website",
  "We boost your brand visibility",
  "We create engaging content",
  "We build your digital presence",
  "We bring your ideas to life",
  "We craft your brand identity",
  "We drive your social growth",
  "We transform your vision"
].map(text => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase());

const AnimatedBorder = styled(motion.div)`
  position: relative;
  padding: 20px 40px;
  margin-top: 1rem;
  border-radius: 50px;
  border: 1px solid #373540;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.$width ? `${props.$width}px` : 'fit-content'};
  min-width: fit-content;
  background: linear-gradient(
    90deg,
    rgba(215, 215, 215, 0.01) 0%,
    rgba(215, 215, 215, 0.02) 20%,
    rgba(215, 215, 215, 0.08) 50%,
    rgba(215, 215, 215, 0.02) 80%,
    rgba(215, 215, 215, 0.01) 100%
  );
  background-size: 200% 100%;
  animation: shimmer 8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  cursor: pointer;
  transform: none;
  overflow: hidden;
  transition: width 0.3s ease;
  
  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent 0%,
      rgba(215, 215, 215, 0.08) 50%,
      transparent 100%
    );
    animation: shine 4s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }

  @keyframes shine {
    0% {
      left: -100%;
    }
    20% {
      left: 100%;
    }
    100% {
      left: 100%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 1px;
    background: linear-gradient(90deg, 
      rgba(215, 215, 215, 0.1) 0%, 
      rgba(215, 215, 215, 0.05) 50%, 
      rgba(215, 215, 215, 0.1) 100%
    );
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: 0.3;
    background-size: 200% 100%;
    animation: borderShimmer 6s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }

  @keyframes borderShimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const AnimatedWord = styled(motion.span)`
  display: inline-block;
  white-space: pre;
  margin-right: 6px;
  
  &:last-child {
    margin-right: 0;
  }
`;

const AnimatedSubTitle = styled(motion.h2)`
  color: #D7D7D7;
  font-size: 20px;
  font-family: 'HelveticaNowVar', sans-serif;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
  word-wrap: break-word;
  
  @media (max-width: 768px) {
    font-size: 18px;
    justify-content: center;
    text-align: center;
  }
`;

const NewSection = styled.section`
  padding: 0px 0px;
  background: rgb(5, 4, 17);
  color: #ffffff;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  max-width: 1400px;
  margin: 100px auto 0;
  padding: 0 5vw;
  font-family: 'HelveticaNowVar', sans-serif;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 3rem;
    margin-top: 50px;
  }
`;

const Column = styled.div`
  flex: 1;
  width: 100%;
  font-family: 'HelveticaNowVar', sans-serif;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const NewText = styled(motion.p)`
  font-size: clamp(1.5rem, 2vw, 2rem);
  max-width: 600px;
  margin-bottom: 3rem;
  opacity: 0.8;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const AccordionWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  padding: 0 1rem;
  font-family: 'HelveticaNowVar', sans-serif;

  @media (max-width: 1024px) {
    max-width: none;
    margin-left: 0;
    padding: 0;
    width: 100%;
  }
`;

const AccordionItem = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 1rem;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  cursor: pointer;
  font-size: clamp(1.8rem, 3vw, 2.2rem);
  width: 100%;
  font-family: 'HelveticaNowVar', sans-serif;
  
  &:hover {
    opacity: 0.8;
  }
`;

const AccordionContent = styled(motion.div)`
  overflow: hidden;
  width: 100%;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const AccordionInner = styled.div`
  padding-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  line-height: 1.4;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const AccordionIcon = styled(motion.div)`
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 2rem;
  flex-shrink: 0;
  font-family: 'HelveticaNowVar', sans-serif;
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    background: #888888; /* Darker color */
    transition: transform 0.3s ease;
  }
  
  &::before {
    width: 100%;
    height: 1px; /* Thinner line */
    top: 50%;
    left: 0;
    transform-origin: 0 0;
    transform: translateY(-50%);
  }
  
  &::after {
    width: 1px; /* Thinner line */
    height: 100%;
    left: 50%;
    top: 0;
    transform-origin: 0 0;
    transform: ${props => props.isOpen ? 'translateX(-50%) scaleY(0)' : 'translateX(-50%)'};
  }
`;

const AccordionSection = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <AccordionWrapper>
      {items.map((item, index) => {
        const isOpen = activeIndex === index;
        
        return (
          <AccordionItem key={index}>
            <AccordionHeader onClick={() => toggleAccordion(index)}>
              <span>{item.title}</span>
              <AccordionIcon isOpen={isOpen} />
            </AccordionHeader>
            <AnimatePresence initial={false}>
              {isOpen && (
                <AccordionContent
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { height: "auto", opacity: 1 },
                    collapsed: { height: 0, opacity: 0 }
                  }}
                  transition={{
                    duration: 0.25,
                    ease: [0.4, 0, 0.2, 1]
                  }}
                >
                  <AccordionInner>
                    {item.description}
                  </AccordionInner>
                </AccordionContent>
              )}
            </AnimatePresence>
          </AccordionItem>
        );
      })}
    </AccordionWrapper>
  );
};

const serviceDescriptions = [
  {
    title: "3D & 2D",
    description: "Elevate your brand with cutting-edge 3D animations and stunning 2D visuals. Our team creates immersive experiences that bring your ideas to life, from product visualizations to animated brand stories that captivate your audience."
  },
  {
    title: "Digital Products",
    description: "Transform your vision into powerful digital solutions. We design and develop intuitive mobile apps, web applications, and digital platforms that solve real problems and deliver exceptional user experiences."
  },
  {
    title: "Websites",
    description: "Create stunning, responsive websites that leave a lasting impression. Our web solutions combine beautiful design with powerful functionality, ensuring your online presence stands out and drives results."
  },
  {
    title: "Development",
    description: "Build robust and scalable digital solutions with our expert development team. From front-end to back-end, we use cutting-edge technologies to create custom software that powers your business growth."
  },
  {
    title: "Generative AI",
    description: "Harness the power of artificial intelligence to revolutionize your business. We integrate advanced AI solutions to automate processes, generate creative content, and provide personalized experiences that set you apart."
  }
];

const VideoSection = styled.section`
  width: 100%;
  background: rgb(5, 4, 17);
  color: white;
  font-family: HelveticaNowVar, sans-serif;
  box-sizing: border-box;
  margin: 0;
`;

const VideoContainer = styled(motion.div)`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  aspect-ratio: 16/9;
  border-radius: 30px;
  overflow: visible;
  cursor: pointer;
  transform-origin: center center;
  will-change: transform;
  perspective: 1000px;
`;

const TheVideoContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 30px;
  overflow: visible;
  cursor: pointer;
  transform-origin: center center;
  will-change: transform;
  perspective: 1000px;

  .video-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    overflow: hidden;
    transform-style: preserve-3d;
  }

  &:hover {
    .video-click-area {
      opacity: 1;
    }
  }
`;

const VideoClickArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  cursor: pointer;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const AnimatedText = styled(motion.div)`
  opacity: 0;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const FeaturedProjectsSection = styled.section`
  padding: 100px 0;
  background: #050411;
  color: white;

  @media (max-width: 768px) {
    padding: 50px 0;
  }
`;

const FeaturedProjectsContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 5vw;
  font-family: 'HelveticaNowVar', sans-serif;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2vw;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  font-family: 'HelveticaNowVar', sans-serif;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;
    max-width: none;
    gap: 2.5rem;
  }
`;

const SeeAllProjectsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const LetsWorkTogetherButton = styled(Link)`
  display: inline-block;
  padding: 1rem 2rem;
  color: white;
  font-size: 1.5rem;
  font-family: 'HelveticaNowVar', sans-serif;
  text-decoration: none;
  border-radius: 50px;
  border: 2px solid white;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
`;

const ContactSection = styled.section`
  padding: 100px 0;
  background: #050411;
  color: white;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  font-family: 'HelveticaNowVar', sans-serif;
`;

const ContactContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 5vw;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const ContactTitle = styled.h2`
  font-size: 3.5rem;
  font-weight: 500;
  margin-bottom: 4rem;
  text-align: center;
  font-family: 'HelveticaNowVar', sans-serif;
  letter-spacing: -0.02em;
`;

const SectionTitle = styled.h2`
  font-size: clamp(2rem, 4vw, 2.5rem);
  margin-bottom: 4rem;
  text-align: center;
  color: ${props => props.light ? '#fff' : '#000'};
  font-weight: 500;
  letter-spacing: -0.02em;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'HelveticaNowVar', sans-serif;
`;

const StyledProjectCard = styled(motion.div)`
  position: relative;
  width: 100%;
  aspect-ratio: 9/14;
  border-radius: 24px;
  overflow: hidden;
  cursor: pointer;
  font-family: 'HelveticaNowVar', sans-serif;
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
    transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
    color: white;
    transform: translateY(100%);
    transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    font-family: 'HelveticaNowVar', sans-serif;
    
    h3 {
      font-size: 2rem;
      margin-bottom: 0.5rem;
      font-weight: 700;
    }
    
    p {
      font-size: 1rem;
      opacity: 0.8;
      font-weight: normal;
    }
  }

  @media (min-width: 769px) {
    &:hover {
      video {
        transform: scale(1.05);
      }
      .content {
        transform: translateY(0);
      }
    }

    video {
      ${props => !props.$isHovered && css`
        filter: none;
      `}
    }
  }

  @media (max-width: 768px) {
    &.in-view {
      video {
        transform: scale(1.05);
        filter: none;
      }
      .content {
        transform: translateY(0);
      }
    }
  }
`;

const ProjectCard = ({ project, index }) => {
  const cardRef = useRef(null);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const isMobile = window.innerWidth <= 768;
  const videoRef = useRef(null);

  useEffect(() => {
    if (isMobile && cardRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setIsInView(true);
              if (videoRef.current) {
                videoRef.current.play();
              }
            } else {
              setIsInView(false);
              if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
              }
            }
          });
        },
        { threshold: 0.5 }
      );

      observer.observe(cardRef.current);
      return () => observer.disconnect();
    }
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile && videoRef.current) {
      if (isHovered) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }
  }, [isHovered, isMobile]);

  const handleClick = () => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      sessionStorage.setItem('projectCardRect', JSON.stringify(rect));
      navigate(`/project/${project.slug}`);
    }
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };

  return (
    <div 
      className="project-card-hover"
      style={{ 
        width: '100%', 
        height: '100%',
        position: 'relative',
      }}
      onClick={handleClick}
      ref={cardRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledProjectCard
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: index * 0.1 }}
        $isHovered={isHovered}
        className={isInView ? 'in-view' : ''}
      >
        <video
          ref={videoRef}
          src={project.cardVideo}
          muted
          loop
          playsInline
          autoPlay={isMobile}
        />
        <div className="content">
          <h3>{project.title}</h3>
          <p>{project.description}</p>
        </div>
      </StyledProjectCard>
    </div>
  );
};

const Home = () => {
  const { projects } = useProjects();
  const navigate = useNavigate();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  
  const [currentSubtitle, setCurrentSubtitle] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(null);
  const buttonRef = useRef(null);
  const intervalRef = useRef(null);

  const theVideoRef = useRef(null);
  const theVideoPlayerRef = useRef(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const player = theVideoPlayerRef.current;
      if (player && !document.fullscreenElement) {
        const videoElement = player.getInternalPlayer();
        if (videoElement) {
          videoElement.muted = true;
        }
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (buttonRef.current) {
        setButtonWidth(buttonRef.current.offsetWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!isHovered) {
      intervalRef.current = setInterval(() => {
        setCurrentSubtitle((prev) => (prev + 1) % subtitles.length);
      }, 6000);

      return () => clearInterval(intervalRef.current);
    }
  }, [isHovered]);

  useEffect(() => {
    if (isFirstLoad) {
      setTimeout(() => {
        setIsFirstLoad(false);
      }, 2000);
    }
  }, [isFirstLoad]);

  const handleHireUsClick = () => {
    navigate('/hire-us');
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setButtonWidth(null);
  };

  const getCurrentText = () => {
    return isHovered ? "Let's work together" : subtitles[currentSubtitle];
  };

  const createWordAnimations = (text) => {
    return text.split(' ').map((word, index) => (
      <AnimatedWord
        key={index}
        initial={{ 
          y: 20, 
          opacity: 0,
          rotateX: 20
        }}
        animate={{ 
          y: 0, 
          opacity: 1,
          rotateX: 0,
          transition: {
            duration: 0.8,
            ease: [0.34, 1.56, 0.64, 1],
            delay: isFirstLoad ? 0.8 + (index * 0.06) : index * 0.06
          }
        }}
        exit={{ 
          y: -8, 
          opacity: 0,
          rotateX: 0,
          transition: {
            duration: 0.3,
            ease: "easeIn",
            delay: 0
          }
        }}
      >
        {word}
      </AnimatedWord>
    ));
  };

  const borderVariants = {
    initial: {
      scale: 0.99,
      opacity: 0,
      y: 20
    },
    animate: {
      scale: 1,
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: [0.34, 1.56, 0.64, 1],
        delay: isFirstLoad ? 0.6 : 0
      }
    },
    exit: {
      scale: 1,
      opacity: 0,
      y: -8,
      transition: {
        duration: 0.3,
        ease: "easeIn"
      }
    }
  };

  const featuredProjects = projects || [];

  const handleTheVideoClick = () => {
    const player = theVideoPlayerRef.current;
    if (player) {
      const videoElement = player.getInternalPlayer();
      if (videoElement) {
        if (document.fullscreenElement) {
          document.exitFullscreen();
          videoElement.muted = true;  // إيقاف الصوت عند الخروج من fullscreen
        } else {
          if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if (videoElement.webkitRequestFullscreen) {
            videoElement.webkitRequestFullscreen();
          } else if (videoElement.msRequestFullscreen) {
            videoElement.msRequestFullscreen();
          }
          videoElement.currentTime = 0;
          videoElement.muted = false;  // تشغيل الصوت عند الدخول في fullscreen
        }
      }
    }
  };

  const { scrollYProgress } = useScroll({
    target: theVideoRef,
    offset: ["start 70%", "end 30%"]
  });

  const scale = useSpring(useMotionValue(1), {
    stiffness: 100,
    damping: 30
  });

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange((value) => {
      if (value >= 0.3) {
        // تكبير تدريجي
        const progress = Math.min((value - 0.3) / 0.4, 1); // من 0.3 إلى 0.7
        const scaleValue = window.innerWidth <= 768 
          ? 1 + (progress * 0.05) // للموبايل: يكبر حتى 1.05
          : 1 + (progress * 0.1);  // للديسكتوب: يكبر حتى 1.1
        scale.set(scaleValue);
      } else {
        scale.set(1);
      }
    });

    return () => unsubscribe();
  }, [scrollYProgress, scale]);

  const rotateX = useSpring(useMotionValue(0), {
    stiffness: 100,
    damping: 30
  });

  return (
    <ResizeObserverWrapper>
      <HomeContainer>
        <HeroSection>
          <HeroContent>
            <HeroColumns>
              <LeftColumn>
                <TitleContainer>
                  <MainTitle>
                    <MainTitleWord initial={{ y: 40, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, ease: [0.34, 1.56, 0.64, 1], delay: 0 }}>All4</MainTitleWord>
                    <MainTitleWord initial={{ y: 40, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, ease: [0.34, 1.56, 0.64, 1], delay: 0.1 }}>Agency,</MainTitleWord>
                    <MainTitleWord initial={{ y: 40, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, ease: [0.34, 1.56, 0.64, 1], delay: 0.2 }}>your</MainTitleWord>
                    <MainTitleWord initial={{ y: 40, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, ease: [0.34, 1.56, 0.64, 1], delay: 0.3 }}>All</MainTitleWord>
                    <MainTitleWord initial={{ y: 40, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, ease: [0.34, 1.56, 0.64, 1], delay: 0.4 }}>in</MainTitleWord>
                    <MainTitleWord initial={{ y: 40, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, ease: [0.34, 1.56, 0.64, 1], delay: 0.5 }}>One</MainTitleWord>
                    <MainTitleWord initial={{ y: 40, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, ease: [0.34, 1.56, 0.64, 1], delay: 0.6 }}>Partner</MainTitleWord>
                    <MainTitleWord initial={{ y: 40, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, ease: [0.34, 1.56, 0.64, 1], delay: 0.7 }}>for</MainTitleWord>
                    <MainTitleWord initial={{ y: 40, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, ease: [0.34, 1.56, 0.64, 1], delay: 0.8 }}>Success.</MainTitleWord>
                  </MainTitle>
                  <AnimatePresence mode="wait">
                    <AnimatedBorder
                      ref={buttonRef}
                      key={isHovered ? "hovered" : currentSubtitle + "-border"}
                      variants={borderVariants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      onClick={handleHireUsClick}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      $width={buttonWidth}
                    >
                      <AnimatedSubTitle key={isHovered ? "hovered" : currentSubtitle}>
                        {createWordAnimations(getCurrentText())}
                      </AnimatedSubTitle>
                    </AnimatedBorder>
                  </AnimatePresence>
                </TitleContainer>
              </LeftColumn>
              <RightColumn>
                <Suspense fallback={null}>
                  <Logo3DWrapper
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                  >
                    <Logo3D />
                  </Logo3DWrapper>
                </Suspense>
              </RightColumn>
            </HeroColumns>
          </HeroContent>
        </HeroSection>

        <VideoSection>
          <VideoContainer>
            <TheVideoContainer
              ref={theVideoRef}
              initial={{ 
                opacity: 0,
                scale: 0.5,
                scaleX: 0.8,
                scaleY: 0.5,
                y: 60
              }}
              whileInView={{ 
                opacity: 1,
                scale: 1,
                scaleX: 1,
                scaleY: 1,
                y: 0,
                transition: {
                  duration: 0.8,
                  ease: [0.23, 1, 0.32, 1],
                  opacity: { duration: 0.4 },
                  scale: {
                    duration: 0.6,
                    ease: [0.34, 1.56, 0.64, 1]
                  },
                  scaleX: {
                    duration: 0.6,
                    ease: [0.34, 1.56, 0.64, 1]
                  },
                  scaleY: {
                    duration: 0.6,
                    ease: [0.34, 1.56, 0.64, 1]
                  },
                  y: { 
                    duration: 0.5,
                    ease: [0.33, 1, 0.68, 1]
                  }
                }
              }}
              style={{
                scale,
                transformPerspective: "1200px",
                transformOrigin: "center center"
              }}
              viewport={{ once: true, margin: "-100px" }}
            >
              <div className="video-wrapper">
                <VideoClickArea 
                  className="video-click-area"
                  onClick={handleTheVideoClick}
                />
                <ReactPlayer
                  ref={theVideoPlayerRef}
                  url="/videos/all4.mp4"
                  width="100%"
                  height="100%"
                  playing={true}
                  muted={true}
                  loop={true}
                  playsinline
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                        disablePictureInPicture: true
                      }
                    }
                  }}
                />
              </div>
            </TheVideoContainer>
          </VideoContainer>
        </VideoSection>

        <div className="main-content">
          <NewSection>
            <SectionContainer>
              <Column>
                <NewText
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  We build transformative digital experiences for the world's leading brands by blending AI, design, and technology.
                </NewText>
              </Column>
              <Column>
                <AccordionSection items={serviceDescriptions} />
              </Column>
            </SectionContainer>
          </NewSection>

          <FeaturedProjectsSection>
            <FeaturedProjectsContent>
              <AnimatedText
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <SectionTitle light>Featured Projects</SectionTitle>
              </AnimatedText>
              <ProjectsGrid>
                {featuredProjects.map((project, index) => (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    index={index}
                  />
                ))}
              </ProjectsGrid>
              <SeeAllProjectsContainer>
                <LetsWorkTogetherButton to="/hire-us">
                  Let's Create Together
                </LetsWorkTogetherButton>
              </SeeAllProjectsContainer>
            </FeaturedProjectsContent>
          </FeaturedProjectsSection>

          <HomeCTAWrapper>
            <CTA />
          </HomeCTAWrapper>

        </div>
      </HomeContainer>
    </ResizeObserverWrapper>
  );
};

const ResizeObserverWrapper = ({ children }) => {
  useEffect(() => {
    const resizeObserverError = error => {
      if (error?.message?.includes?.('ResizeObserver')) {
        error.preventDefault();
        return true;
      }
      return false;
    };

    window.addEventListener('error', resizeObserverError);
    window.addEventListener('unhandledrejection', resizeObserverError);

    return () => {
      window.removeEventListener('error', resizeObserverError);
      window.removeEventListener('unhandledrejection', resizeObserverError);
    };
  }, []);

  return children;
};

const HomeCTAWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 120px auto;
  
  > div {
    background: transparent !important;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
  }
`;

export default Home;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Logo from './Logo';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Register the ScrollTrigger plugin
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
}

const NavContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 1.5rem 5vw;
  width: 100%;
  transform: translateY(${props => props.$hideNav ? '-100%' : '0'});
  transition: transform 0.3s ease, background-color 0.3s ease;
  background: ${props => props.$isScrolled ? 'rgba(5, 4, 17, 0.75)' : 'transparent'};
  backdrop-filter: ${props => props.$isScrolled ? 'blur(12px)' : 'none'};
  -webkit-backdrop-filter: ${props => props.$isScrolled ? 'blur(12px)' : 'none'};
  border-bottom: ${props => props.$isScrolled ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'};
`;

const NavBorder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1),
    transparent
  );
  opacity: ${props => props.$isScrolled ? 1 : 0};
  transition: opacity 0.3s ease;
  z-index: 2;
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-color);
  padding: 0.5rem;
  position: relative;
  font-family: var(--primary-font);
  mix-blend-mode: difference;
`;

const DesktopMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  position: absolute;
  left: 200px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const DesktopLink = styled(Link)`
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.25rem;
  font-family: var(--primary-font);
  position: relative;
  padding: 0.5rem;
  transition: color 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: var(--text-color);
    transform: scaleX(0);
    transition: transform 0.3s ease;
    transform-origin: left;
  }

  &:hover::after {
    transform: scaleX(1);
  }

  &.active {
    color: var(--text-color-secondary);
  }
`;

const HireUsContainer = styled.div`
  margin-left: auto;
  
  @media (max-width: 1200px) {
    display: none;
  }
`;

const HireUsButton = styled(Link)`
  padding: 20px 40px;
  border-radius: 50px;
  color: var(--text-color);
  text-decoration: none;
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 1.25rem;
  background: transparent;
  border: 1px solid var(--border-color);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    background: var(--text-color);
    color: var(--background-color);
    border-color: var(--text-color);
  }

  &.active {
    background: var(--text-color);
    color: var(--background-color);
    border-color: var(--text-color);
  }
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  display: none;
  font-family: var(--primary-font);
  position: relative;
  z-index: 102;
  mix-blend-mode: difference;

  @media (max-width: 1200px) {
    display: block;
  }
`;

const HamburgerIcon = styled.div`
  width: 24px;
  height: 2px;
  background-color: var(--text-color);
  position: relative;
  transition: all 0.3s ease;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: var(--text-color);
    transition: all 0.3s ease;
  }

  &::before {
    transform: translateY(-8px);
  }

  &::after {
    transform: translateY(8px);
  }

  ${({ isOpen }) => isOpen && `
    background-color: transparent;
    
    &::before {
      transform: rotate(45deg);
    }
    
    &::after {
      transform: rotate(-45deg);
    }
  `}
`;

const MobileMenu = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--background-color);
  z-index: 101;
  overflow-y: auto;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 5vw;

  @media (min-width: 1200px) {
    display: none;
  }
`;

const MobileMenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;

  a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 3rem;
    font-weight: 500;
    transition: opacity 0.3s ease;
    text-align: center;

    &:hover {
      opacity: 0.8;
    }

    &.active {
      opacity: 0.8;
    }
  }
`;

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [hideNav, setHideNav] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      
      // Only start hiding after 400px
      if (currentScrollPos > 400) {
        const visible = prevScrollPos > currentScrollPos;
        setHideNav(!visible);
      } else {
        setHideNav(false);
      }

      setPrevScrollPos(currentScrollPos);
      setIsScrolled(currentScrollPos > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    if (isMenuOpen) {
      setHideNav(false);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    setIsMenuOpen(false);
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    document.body.style.opacity = '1'; // Reset opacity when component mounts
  }, [location]);

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleNavClick = () => {
    document.body.style.opacity = '0'; // Immediately hide content
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <NavContainer $hideNav={hideNav} $isScrolled={isScrolled}>
      <NavBorder $isScrolled={isScrolled} />
      <NavContent>
        <LogoContainer to="/">
          <Logo />
        </LogoContainer>
        <DesktopMenu>
          <DesktopLink to="/" className={isActive('/') ? 'active' : ''} onClick={handleNavClick}>
            Portfolio
          </DesktopLink>
          <DesktopLink to="/services" className={isActive('/services') ? 'active' : ''} onClick={handleNavClick}>
            Services
          </DesktopLink>
          <DesktopLink to="/about" className={isActive('/about') ? 'active' : ''} onClick={handleNavClick}>
            About
          </DesktopLink>
          <DesktopLink to="/hire-us" className={isActive('/hire-us') ? 'active' : ''} onClick={handleNavClick}>
            Contact
          </DesktopLink>
        </DesktopMenu>
        <HireUsContainer>
          <HireUsButton to="/hire-us" className={isActive('/hire-us') ? 'active' : ''} onClick={handleNavClick}>
            Let's create together
          </HireUsButton>
        </HireUsContainer>
        <MenuButton onClick={toggleMenu}>
          <HamburgerIcon isOpen={isMenuOpen} />
        </MenuButton>
      </NavContent>
      <AnimatePresence>
        {isMenuOpen && (
          <MobileMenu
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <MobileMenuLinks>
              <Link to="/" className={isActive('/') ? 'active' : ''} onClick={handleNavClick}>
                Portfolio
              </Link>
              <Link to="/services" className={isActive('/services') ? 'active' : ''} onClick={handleNavClick}>
                Services
              </Link>
              <Link to="/about" className={isActive('/about') ? 'active' : ''} onClick={handleNavClick}>
                About
              </Link>
              <Link to="/hire-us" className={isActive('/hire-us') ? 'active' : ''} onClick={handleNavClick}>
                Contact
              </Link>
              <Link to="/hire-us" className="hire-us" onClick={handleNavClick}>
                Let's talk
              </Link>
            </MobileMenuLinks>
          </MobileMenu>
        )}
      </AnimatePresence>
    </NavContainer>
  );
};

export default Navigation;

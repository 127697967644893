import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const CTASection = styled.div`
  margin: 120px 0;
  padding: 80px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  text-align: center;

  @media (max-width: 768px) {
    padding: 40px 20px;
    margin: 60px 0;
  }
`;

const CTATitle = styled(motion.h2)`
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  color: #fff;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 1.2;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const CTADescription = styled(motion.p)`
  font-size: clamp(1.1rem, 2vw, 1.5rem);
  color: #888;
  margin-bottom: 40px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;

  @media (max-width: 768px) {
    margin-bottom: 30px;
    font-size: 1.1rem;
  }
`;

const CTAButton = styled(motion.button)`
  background: #fff;
  color: #000;
  border: none;
  padding: 15px 40px;
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  font-weight: 500;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: var(--primary-font);

  &:hover {
    background: rgba(255, 255, 255, 0.9);
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    padding: 12px 30px;
    font-size: 1rem;
  }
`;

const CTA = ({ title, description, buttonText }) => {
  const navigate = useNavigate();

  return (
    <CTASection>
      <CTATitle
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        {title || "Ready to Transform Your Vision?"}
      </CTATitle>
      <CTADescription
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        {description || "Let's collaborate to bring your ideas to life and create something extraordinary together."}
      </CTADescription>
      <CTAButton
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.4 }}
        onClick={() => navigate('/hire-us')}
      >
        <span>{buttonText || "Let's Start"}</span>
      </CTAButton>
    </CTASection>
  );
};

export default CTA;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const HireUsContainer = styled.div`
  background: #050411;
  min-height: 100vh;
  color: #fff;
  padding: 250px 5vw;
  font-family: var(--primary-font);
`;

const HireUsContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 60px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const MainContent = styled.div`
  width: 100%;
`;

const InfoColumn = styled.div`
  position: sticky;
  top: 180px;
  height: fit-content;
  padding: 60px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(10px);

  @media (max-width: 1024px) {
    padding: 40px;
  }
`;

const CompanyInfo = styled.div`
  h3 {
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 2rem;
    font-family: var(--primary-font);
    letter-spacing: -0.02em;
    line-height: 1.1;
  }

  p {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 3rem;
    line-height: 1.6;
    font-family: var(--primary-font);
  }
`;

const ContactInfo = styled.div`
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  h4 {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-family: var(--primary-font);
    letter-spacing: -0.01em;
  }

  p {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 2rem;
    line-height: 1.6;
    font-family: var(--primary-font);
  }

  a {
    display: block;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-family: var(--primary-font);
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

    &:hover {
      color: white;
      transform: translateX(10px);
    }
  }
`;

const FormColumn = styled.div`
  width: 100%;
`;

const FormStep = styled(motion.div)`
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
`;

const InputGroup = styled.div`
  position: relative;
  margin: 2rem 0;
`;

const Label = styled.label`
  position: absolute;
  left: 0;
  color: ${props => props.error ? '#c14646' : 'rgba(255, 255, 255, 0.4)'};
  font-size: 1rem;
  font-family: var(--primary-font);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform-origin: 0 0;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
`;

const FormInput = styled.input`
  width: 100%;
  padding: 1rem 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid ${props => props.error ? '#c14646' : 'rgba(255, 255, 255, 0.05)'};
  color: white;
  font-size: 1.2rem;
  font-family: var(--primary-font);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
    border-bottom-color: ${props => props.error ? '#c14646' : 'rgba(255, 255, 255, 0.2)'};
  }

  &:focus + ${Label}, &:not(:placeholder-shown) + ${Label} {
    transform: translateY(-1.5rem) scale(0.8);
    color: ${props => props.error ? '#c14646' : 'rgba(255, 255, 255, 0.6)'};
  }

  &::placeholder {
    color: transparent;
  }
`;

const FormTextArea = styled.textarea`
  width: 100%;
  padding: 1rem 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid ${props => props.error ? '#c14646' : 'rgba(255, 255, 255, 0.05)'};
  color: white;
  font-size: 1.2rem;
  font-family: var(--primary-font);
  min-height: 150px;
  resize: none;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
    border-bottom-color: ${props => props.error ? '#c14646' : 'rgba(255, 255, 255, 0.2)'};
  }

  &:focus + ${Label}, &:not(:placeholder-shown) + ${Label} {
    transform: translateY(-1.5rem) scale(0.8);
    color: ${props => props.error ? '#c14646' : 'rgba(255, 255, 255, 0.6)'};
  }

  &::placeholder {
    color: transparent;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${props => props.step === 0 ? 'flex-end' : 'space-between'};
  margin-top: 4rem;
  width: 100%;
`;

const StepButton = styled.button`
  padding: 1.2rem 2.5rem;
  margin: 1rem;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: var(--primary-font);
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: rgba(255, 255, 255, 0.05);
    transform: translateY(-2px);
  }
`;

const FormTitle = styled.h3`
  font-size: clamp(6rem, 5vw, 3.5rem);
  max-width: 700px;
  color: #fff;
  margin-bottom: 100px;
  font-weight: 750;
  line-height: 1.2;
  font-family: var(--primary-font);
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0.7) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 3rem;
    margin-bottom: 60px;
  }
`;

const pageTransition = {
  initial: { opacity: 0, y: 20 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.16, 1, 0.3, 1]
    }
  },
  exit: { 
    opacity: 0, 
    y: -20,
    transition: {
      duration: 0.6,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const StatusMessage = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  
  ${props => props.status === 'success' && `
    color: #00ff00;
    background: rgba(0, 255, 0, 0.1);
    border: 1px solid rgba(0, 255, 0, 0.2);
  `}
  
  ${props => props.status === 'error' && `
    color: #c14646;
    background: rgba(193, 70, 70, 0.1);
    border: 1px solid rgba(193, 70, 70, 0.2);
  `}
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`;

const HireUsForm = () => {
  const [step, setStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    projectType: '',
    budget: '',
    timeline: '',
    description: ''
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: false
      }));
    }
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'email':
        return validateEmail(value);
      case 'name':
      case 'phone':
        return value.trim() !== '';
      default:
        return true;
    }
  };

  const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate required fields
    if (!formData.name || !formData.email || !formData.phone) {
      setSubmitStatus('error');
      setStatusMessage('Please fill in all required fields (Name, Email, and Phone)');
      return;
    }

    // Validate email format
    if (!validateEmail(formData.email)) {
      setSubmitStatus('error');
      setStatusMessage('Please enter a valid email address');
      return;
    }

    if (!executeRecaptcha) {
      setSubmitStatus('error');
      setStatusMessage('reCAPTCHA not ready yet');
      return;
    }
    
    setIsSubmitting(true);
    setStatusMessage('');
    
    try {
      const recaptchaToken = await executeRecaptcha('hire_us_submit');
      
      const response = await fetch('https://all4.agency/send-email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          recaptchaToken
        })
      });

      // If we get any response, consider it a success since we see in logs that email is sent
      setSubmitStatus('success');
      setStatusMessage('Thank you! Your message has been sent successfully.');
      setFormData({
        name: '',
        email: '',
        phone: '',
        company: '',
        projectType: '',
        budget: '',
        timeline: '',
        description: ''
      });
      setStep(0);
      
    } catch (error) {
      console.error('Form submission error:', error);
      setSubmitStatus('error');
      setStatusMessage('Failed to send message. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderSubmitButton = () => (
    <StepButton 
      type="submit" 
      disabled={isSubmitting}
      onClick={handleSubmit}
    >
      {isSubmitting ? (
        <span>
          Sending...
          <LoadingSpinner />
        </span>
      ) : (
        'Submit'
      )}
    </StepButton>
  );

  const nextStep = () => {
    if (step === 0) {
      const newErrors = {
        name: !formData.name,
        email: !formData.email || !validateEmail(formData.email),
        phone: !formData.phone
      };

      setErrors(newErrors);

      if (Object.values(newErrors).some(error => error)) {
        return;
      }
    }
    setStep((prevStep) => prevStep + 1);
    setSubmitStatus(null);
    setStatusMessage('');
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <FormStep key="step1" {...pageTransition}>
            <FormTitle>Tell us about yourself</FormTitle>
            <InputGroup>
              <FormInput 
                type="text" 
                placeholder="Your full name"
                required
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                error={errors.name}
              />
              <Label htmlFor="name" error={errors.name}>Name *</Label>
            </InputGroup>
            <InputGroup>
              <FormInput 
                type="email" 
                placeholder="Your email address"
                required
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                error={errors.email}
                pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                title="Please enter a valid email address"
              />
              <Label htmlFor="email" error={errors.email}>Email *</Label>
            </InputGroup>
            <InputGroup>
              <FormInput 
                type="tel" 
                placeholder="Your phone number"
                required
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                error={errors.phone}
              />
              <Label htmlFor="phone" error={errors.phone}>Phone *</Label>
            </InputGroup>
            <InputGroup>
              <FormInput 
                type="text" 
                placeholder="Your company name"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
              />
              <Label htmlFor="company">Company (Optional)</Label>
            </InputGroup>
            <ButtonContainer step={0}>
              <StepButton type="button" onClick={nextStep}>Next</StepButton>
            </ButtonContainer>
          </FormStep>
        );

      case 1:
        return (
          <FormStep key="step2" {...pageTransition}>
            <FormTitle>Project Details</FormTitle>
            <InputGroup>
              <FormInput 
                type="text" 
                placeholder="Website, Mobile App, Branding..."
                id="projectType"
                name="projectType"
                value={formData.projectType}
                onChange={handleInputChange}
              />
              <Label htmlFor="projectType">Project Type</Label>
            </InputGroup>
            <InputGroup>
              <FormTextArea 
                placeholder="Tell us about your project..."
                id="description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
              <Label htmlFor="description">Description</Label>
            </InputGroup>
            <InputGroup>
              <FormInput 
                type="text" 
                placeholder="Estimated budget"
                id="budget"
                name="budget"
                value={formData.budget}
                onChange={handleInputChange}
              />
              <Label htmlFor="budget">Budget</Label>
            </InputGroup>
            <ButtonContainer>
              <StepButton type="button" onClick={prevStep}>Back</StepButton>
              <StepButton type="button" onClick={nextStep}>Next</StepButton>
            </ButtonContainer>
          </FormStep>
        );

      case 2:
        return (
          <FormStep key="step3" {...pageTransition}>
            <FormTitle>Final Details</FormTitle>
            <InputGroup>
              <FormInput 
                type="text" 
                placeholder="When would you like to start?"
                id="timeline"
                name="timeline"
                value={formData.timeline}
                onChange={handleInputChange}
              />
              <Label htmlFor="timeline">Timeline</Label>
            </InputGroup>
            <InputGroup>
              <FormTextArea 
                placeholder="Anything else we should know?"
                id="additional"
                name="additional"
                value={formData.additional}
                onChange={handleInputChange}
              />
              <Label htmlFor="additional">Additional Info (Optional)</Label>
            </InputGroup>
            <ButtonContainer>
              <StepButton type="button" onClick={prevStep}>Back</StepButton>
              {renderSubmitButton()}
            </ButtonContainer>
            {statusMessage && (
              <StatusMessage status={submitStatus}>
                {statusMessage}
              </StatusMessage>
            )}
          </FormStep>
        );
      default:
        return null;
    }
  };

  return (
    <HireUsContainer>
      <HireUsContent>
        <MainContent>
          <AnimatePresence mode="wait">
            {submitStatus === 'success' ? (
              <SuccessMessage
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <h2>Thank you!</h2>
                <p>{statusMessage}</p>
              </SuccessMessage>
            ) : (
              <form onSubmit={handleSubmit}>
                {renderStep()}
              </form>
            )}
          </AnimatePresence>
        </MainContent>
        
        <InfoColumn>
          <CompanyInfo>
            <h3>Lets work Together</h3>
          </CompanyInfo>
          <ContactInfo>
            <h4>Get in Touch</h4>
            <p>Building 30, Apartment 8<br />Moulay Ahmed Loukili Street<br />Rabat, Morocco</p>
            <a href="mailto:hello@all4.agency">hello@all4.agency</a>
            <a href="tel:+212707370707">+212 707 370 707</a>
            
            <h4>Working Hours</h4>
            <p>Monday - Friday<br />9:00 AM - 6:00 PM PST</p>
          </ContactInfo>
        </InfoColumn>
      </HireUsContent>
    </HireUsContainer>
  );
};

const HireUs = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdbOqkqAAAAAPLL2aG39Q_5FTFHRRgtpYvHPB2i">
      <HireUsForm />
    </GoogleReCaptchaProvider>
  );
};

const SuccessMessage = styled(motion.div)`
  text-align: center;
  padding: 2rem;
  
  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.2rem;
    opacity: 0.8;
  }
`;

export default HireUs;

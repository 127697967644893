import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import CTA from './CTA';

const FAQContainer = styled.div`
  padding: 280px 5% 100px;
  background: var(--background);
  color: #fff;
  min-height: 100vh;
`;

const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

const Title = styled(motion.h1)`
  font-size: clamp(4rem, 8vw, 6rem);
  margin-bottom: 100px;
  font-weight: 750;
  line-height: 1.2;
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0.7) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 1200px) {
    font-size: clamp(4rem, 8vw, 6rem);
  }

  @media (max-width: 768px) {
    font-size: clamp(4rem, 7vw, 5rem);
  }

  @media (max-width: 480px) {
    font-size: clamp(3rem, 6vw, 4rem);
  }
`;

const FAQSection = styled(motion.div)`
  margin-bottom: 120px;
`;

const Question = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #fff;
  font-weight: 600;
`;

const Answer = styled.p`
  font-size: 1.5rem;
  line-height: 1.6;
  color: #888;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const FAQ = () => {
  return (
    <FAQContainer>
      <Content>
        <Title
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 1,
            ease: [0.19, 1, 0.22, 1],
          }}
        >
          Frequently Asked Questions
        </Title>

        <FAQSection
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <Question>What services do you offer?</Question>
          <Answer>
            We offer a comprehensive range of digital services including web development, digital marketing, branding, and strategic consulting. Our team specializes in creating custom solutions tailored to your specific needs and goals.
          </Answer>
        </FAQSection>

        <FAQSection
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <Question>How do I get started with your agency?</Question>
          <Answer>
            Getting started is easy! You can book a meeting through our calendar, send us a message on WhatsApp, or contact us via email. We'll schedule a consultation to discuss your project requirements and create a tailored solution for you.
          </Answer>
        </FAQSection>

        <FAQSection
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.8 }}
        >
          <Question>What is your typical project timeline?</Question>
          <Answer>
            Project timelines vary depending on the scope and complexity of your requirements. We provide detailed timelines during our initial consultation and keep you updated throughout the project development process.
          </Answer>
        </FAQSection>

        <FAQSection
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1 }}
        >
          <Question>Do you provide ongoing support?</Question>
          <Answer>
            Yes, we offer comprehensive support and maintenance services for all our projects. We believe in building long-term relationships with our clients and ensuring their digital solutions continue to perform optimally.
          </Answer>
        </FAQSection>
        <CTA />
      </Content>
    </FAQContainer>
  );
};

export default FAQ;
